(function (angular, moment, _, saveAs) {
    var transform = (function loadBabel () {
        function include(url, cb) {
            var head = document.getElementsByTagName('head')[0];

            var script = document.createElement('script');
            script.src = url;
            script.type = 'text/javascript';
            script.onload = cb;

            head.appendChild(script);
        }

        var babelEnabled = true;
        var isIE = !!window.MSInputMethodContext && !!document.documentMode;
        var cache = {};
        var babelConfig;

        if (babelEnabled) {
            include('https://unpkg.com/@babel/standalone/babel.min.js', function () {
                if (isIE) {
                    include('https://unpkg.com/@babel/standalone@7.9.6/babel.min.js', function () {
                        babelConfig = {presets: [["env", { "modules": false }]]};
                    });
                } else {
                    babelConfig = {presets: [["es2015", { "modules": false }]]};
                }
            });
        }

        return function babelTransform (code) {
            var _code = `const functionCall = () => (${code});`;
            if (!babelConfig) return {code: _code};

            if (!cache[code]) {
                cache[code] = Babel.transform(_code, babelConfig);
            }

            return cache[code];
        };
    })();

    angular.module('MyHippoCommons.Workflow', []);

    angular.module('MyHippoCommons.Workflow').controller('WorkflowController', WorkflowController);
    WorkflowController.$inject = ['$scope', '$state', '$stateParams', '$q', 'toaster', 'spinnerService', 'WorkflowUtil', 'UserService'];
    function WorkflowController ($scope, $state, $stateParams, $q, toaster, spinnerService, WorkflowUtil, UserService) {
        var vm = this;

        // init
        var hasNext = false; var hasBack = false;
        var hasIcon = false;
        $scope.workflow.pages.forEach(function (page) {
            if (page.properties.key === $scope.workflow.properties.first_wizard_page) { hasNext = true; hasIcon = true; }
            if (page.properties.key === $scope.workflow.properties.last_wizard_page) hasNext = false;

            page.hasNext = hasNext; page.hasBack = hasBack; page.hasIcon = hasIcon;

            if (page.properties.key === $scope.workflow.properties.first_wizard_page) hasBack = true;
            if (page.properties.key === $scope.workflow.properties.last_wizard_page) { hasBack = false; hasIcon = false; }

            page.sections.forEach(section => {
                section.viewType = 'form';
                if (section.properties.type === 'grid') {
                    section.viewType = 'grid.table';
                    if (section.properties.format === 'forms') section.viewType = 'grid.forms';
                }
                if (section.properties.type === 'address') section.viewType = 'address';
                if (section.properties.type === 'stripe') section.viewType = 'stripe';
            });
        });

        var nextFieldIndex = 0;
        $scope.generateFieldId = function (fieldKey) {
            return ((fieldKey || '') + '_' + Date.now() + '_' + ++nextFieldIndex).replace(/[^a-z0-9]+/gi, '_');
        };

        $scope.inheritProperties = function (from, to) {
            var inheritAttributes = $scope.workflow.properties.inheritAttributes;
            var inheritProperties = _.pick(from, inheritAttributes);

            return _.assignIn(inheritProperties, to);
        };

        // visible page controller
        var pageCtrl;
        vm.setPageCtrl = function (ctrl) { pageCtrl = ctrl; };
        vm.getPageCtrl = function () { return pageCtrl; };

        $scope.evalExp = function (exp, scope) {
            scope = scope || {};
            return WorkflowUtil.evaluateExpression(
                exp,
                scope.model || $scope.workflowModel,
                UserService.getRoles(),
                UserService.getCurrentUser(),
                WorkflowUtil.getWorkflowObjectInfo(scope.item)
            );
        };
    }

    angular.module('MyHippoCommons.Workflow').controller('WorkflowPageController', WorkflowPageController);
    WorkflowPageController.$inject = ['$log', '$scope', '$stateParams', '$q', 'WorkflowUtil'];
    function WorkflowPageController ($log, $scope, $stateParams, $q, WorkflowUtil) {
        var vm = this;

        // find wfPage by pageKey
        vm.wfPage = _.find($scope.workflow.pages, function (page) {
            return page.properties.key === $stateParams.pageKey;
        });

        if (vm.wfPage.properties.get_route) {
            $log.info(vm.wfPage.properties.get_route);
            $scope.pageModel = WorkflowUtil.getWorkflowObjectInfo({});
            WorkflowUtil.getWorkflowRoute($scope.evalExp(vm.wfPage.properties.get_route)).then(function (pageInfo) {
                WorkflowUtil.extendWorkflowModel($scope.pageModel, pageInfo);

                $scope.evalExp = function (exp, scope) {
                    scope = scope || {};
                    scope.model = $scope.pageModel;
                    return $scope.$parent.evalExp(exp, scope);
                };
            });
        } else {
            $scope.pageModel = $scope.workflowModel;
        }

        if (angular.element('.containerSection-left')[0]) angular.element('.containerSection-left')[0].scrollTop = 0;

        // array with all section controllers
        var sectionsCtrl = [];
        vm.addSectionCtrl = function (ctrl) { sectionsCtrl.push(ctrl); };

        // inherit properties
        vm.wfPage.properties = $scope.inheritProperties($scope.workflow.properties, vm.wfPage.properties);
        vm.getProperty = function (property) {
            if (!_.has(vm.wfPage.properties, property)) return;
            return $scope.evalExp(vm.wfPage.properties[property]);
        };
        $scope.workflow.pages.forEach(function (page) {
            page.properties.title = $scope.evalExp(page.properties.title);
        });

        vm.validate = function () {
            var valid = true;
            sectionsCtrl.forEach(function (sc) {
                valid = sc.validate() && valid;
            });
            return valid;
        };

        vm.getDeltaUpdate = function (updates = {}, forSave) {
            return $q.all(sectionsCtrl.map(function (sc) {
                return sc.getDeltaUpdate(updates, forSave);
            }));
        };

        $scope.$watch('pageModel', function () {
            var deltaUpdates = {};
            vm.getDeltaUpdate(deltaUpdates).then(() => {
                vm.pageDeltaUpdates = deltaUpdates;
                vm.hasChanges = !_.isEmpty(deltaUpdates);
            });
        }, true);
    }

    function WorkflowSectionBasicController ($scope, $q, toaster) {
        // console.log('WorkflowSectionBasicController', $scope.wfSection.properties.title);
        var vm = this;

        // inherit properties
        $scope.wfSection.properties = $scope.inheritProperties($scope.pageCtrl.wfPage.properties, $scope.wfSection.properties);
        vm.getProperty = function (property, item) {
            if (!_.has($scope.wfSection.properties, property)) return;
            return $scope.evalExp($scope.wfSection.properties[property], {item: item});
        };
    }

    angular.module('MyHippoCommons.Workflow').controller('WorkflowSectionFormController', WorkflowSectionFormController);
    function WorkflowSectionFormController ($scope, $q, toaster, $log) {
        WorkflowSectionBasicController.call(this, $scope, $q, toaster);
        // console.log('WorkflowSectionFormController', $scope.wfSection.properties.title);
        var vm = this;

        // form field controllers
        vm.editing = false;
        vm.fieldsCtrl = [];
        vm.addFieldCtrl = function (ctrl) { vm.fieldsCtrl.push(ctrl); };

        // map form fields
        var parentKey = vm.getProperty('parent_key');
        parentKey && $scope.wfSection.fields.forEach(function (field) {
            if (field.parent_key_included) return;
            field.key = parentKey + '.' + field.key;
            field.parent_key_included = true;
        });

        vm.edit = function () {
            vm.editing = true;
            vm.fieldsCtrl.forEach(function (fc) {
                fc.getWfField().readonly = false;
            });
            $scope.pageModel['__trigger_digest'] = Date.now();
        };

        vm.cancel = function () {
            vm.editing = false;
            vm.fieldsCtrl.forEach(function (fc) {
                fc.resetField();
                fc.getWfField().readonly = true;
            });
            $scope.pageModel['__trigger_digest'] = Date.now();
            // TODO: reset
            $log.info('cancelling');
        };

        vm.save = function () {
            const delta = {};
            const isValid = vm.validate();
            $log.info('isValid', isValid);
            if (isValid) {
                vm.getDeltaUpdate(delta, true).then(_ => {
                    console.log('need to populate section name', delta, _);
                    $scope.doPageSave(vm.getProperty('key'), delta).then(() => {
                        vm.fieldsCtrl.forEach(function (fc) {
                            fc.getWfField().readonly = true;
                        });
                        vm.editing = false;
                        $scope.pageModel['__trigger_digest'] = Date.now();
                    });
                });
            } else {
                $log.info('not valid', isValid);
            }
        };

        vm.validate = function () {
            var valid = true;
            vm.fieldsCtrl.forEach(function (fc) {
                valid = fc.validate() && valid;
            });
            return valid;
        };
        vm.getDeltaUpdate = function (updates = {}, forSave) {
            return $q.all(
                vm.fieldsCtrl.map(function (fc) {
                    return fc.getDeltaUpdate(updates, forSave);
                })
            );
        };
    }

    angular.module('MyHippoCommons.Workflow').controller('WorkflowSectionAddressController', WorkflowSectionAddressController);
    WorkflowSectionAddressController.$inject = ['$log', '$scope', '$q', 'toaster'];
    function WorkflowSectionAddressController ($log, $scope, $q, toaster) {
        WorkflowSectionFormController.call(this, $scope, $q, toaster);
        $log.info('WorkflowSectionAddressController', $scope.wfSection.properties.title);
        var vm = this;

        $scope.$on('field.changed', function () {
            // if other fields in the section change, reste the live address value
            vm.addressField.templateOptions.reset++;
        });
        $scope.$watch('pageModel', function () {
            vm.liveAddressModel['__trigger_digest'] = Date.now();
        }, true);

        vm.liveAddressModel = {};
        vm.addressField = {
            // test addr: 166 Winfield St, San Francisco CA
            // test addr: 1101 N Fair Oaks Ave, Sunnyvale CA
            // need apt.: 122 Mast Rd Lee, NH
            key: 'obj',
            type: 'liveaddress',
            wrapper: ['errorMessages', 'formLabel', 'mainWrapper'],
            templateOptions: {
                label: 'Suggest Property Address',
                filterState: '',
                reset: 0,
                onChange: function () {
                    var validatedAddress = vm.liveAddressModel.obj || {};

                    // if (_.isEmpty(validatedAddress)) return ;

                    $scope.wfSection.fields.forEach(function (field) {
                        var addrKey = _.last(field.key.split('.'));
                        _.set($scope.pageModel, field.key, validatedAddress[addrKey]);
                    });
                }
            },
            hideExpression: function () {
                return vm.getProperty('readonly');
            },
            expressionProperties: {
                'templateOptions.filterState': function ($viewModel, $modelValue, scope) {
                    var stateField = _.find($scope.wfSection.fields, field => field.key.endsWith('state'));
                    if (!stateField) return '';
                    if (!$scope.evalExp(stateField.readonly)) return '';

                    return _.get($scope.pageModel, stateField.key);
                },
                'templateOptions.isWarningError': function ($viewModel, $modelValue, scope) {
                    // console.log('isWarningError: ', scope.fc.$error);
                    return scope.fc.$error.needsApartment;
                },
                'templateOptions.reset': () => vm.addressField.templateOptions.reset
            },
            ngModelAttrs: {
                filterState: {attribute: 'filter-state'},
                reset: {attribute: 'reset'}
            },
            validation: {
                show: true,
                messages: {
                    invalidAddress: () => 'SmartyStreet: Invalid Address',
                    needsApartment: () => 'SmartyStreet: Needs Apartment'
                }
            }
        };
        vm.fields = [vm.addressField];
    }

    angular.module('MyHippoCommons.Workflow').controller('WorkflowSectionStripeController', WorkflowSectionStripeController);
    WorkflowSectionStripeController.$inject = ['$scope', '$q', 'toaster'];
    function WorkflowSectionStripeController ($scope, $q, toaster) {
        WorkflowSectionFormController.call(this, $scope, $q, toaster);
        // console.log('WorkflowSectionStripeController', $scope.wfSection.properties.title);
        const vm = this;
        const stripe = Stripe(_.get($scope.pageModel, 'checkout_data.stripe_key'));

        const updateViewState = function () {
            $scope.wfSection.fields.forEach(function (field) {
                if (field.format !== 'card_info') return;

                vm.hasCardId = _.get($scope.pageModel, field.key);
                vm.showAddForm = !vm.hasCardId;
            });
        };
        updateViewState();
        $scope.$on('policyupdate.success', function (event, mass) {
            updateViewState();
            if (field.templateOptions.cardElement) field.templateOptions.cardElement.clear();
        });
        $scope.$on('policyupdate.error', function (event, mass) {
            vm.model['__trigger_digest'] = Date.now();
        });

        vm.stripeServerError = {};
        vm.model = {};

        const field = {
            key: 'cardElement',
            type: 'stripeCard',
            wrapper: ['errorMessages', 'formLabel', 'mainWrapper'],
            templateOptions: {
                label: 'Card Info',
                stripe: stripe,
                cardElement: ''
            },
            expressionProperties: {
                'templateOptions.serverError': function () {
                    if (vm.stripeServerError.field === 'cardElement') return vm.stripeServerError;
                    return _.find($scope.workflowErrors, function (srvErr) {
                        if (!srvErr || !srvErr.fields) return;
                        return srvErr.fields.find(f => f.endsWith('payment_token'));
                    });
                }
            }
        };

        vm.fields = [field];

        vm.getDeltaUpdate = function (updates = {}, forSave) {
            if (!vm.getProperty('visible') || !vm.showAddForm || field.templateOptions.cardElement._empty || !forSave) return;

            return stripe.createToken(field.templateOptions.cardElement).then(function (response) {
                if (!response || !response.token) throw new Error(response.error.message);

                $scope.wfSection.fields.forEach(function (field) {
                    if (!field.key.endsWith('payment_token')) return;

                    _.set($scope.pageModel, field.key, response.token.id);
                });

                vm.stripeServerError = {};
                vm.model['__trigger_digest'] = Date.now();
            }).catch(function (err) {
                vm.stripeServerError = {
                    field: 'cardElement', agent_message: err.message
                };
                vm.model['__trigger_digest'] = Date.now();
                throw err;
            }).then(function () {
                return $q.all(
                    vm.fieldsCtrl.map(function (fc) {
                        return fc.getDeltaUpdate(updates, forSave);
                    })
                );
            });
        };
    }

    angular.module('MyHippoCommons.Workflow').controller('WorkflowSectionGridController', WorkflowSectionGridController);
    WorkflowSectionGridController.$inject = ['$scope', '$q', '$timeout', 'toaster', 'WorkflowUtil'];
    function WorkflowSectionGridController ($scope, $q, $timeout, toaster, WorkflowUtil) {
        WorkflowSectionBasicController.call(this, $scope, $q, toaster);
        // console.log('WorkflowSectionGridController', $scope.wfSection.properties.title);
        var vm = this;

        vm.isGrid = true;

        // map field controllers in a KeyValue object by rows
        var rowFieldsCtrl = {};

        // init grid model
        const getGridModelItems = () => _.get($scope.pageModel, vm.getProperty('parent_key'), []);
        $scope.initGrid = function () {
            rowFieldsCtrl = {};
            vm.gridModel = _.cloneDeep(getGridModelItems());
        };
        $scope.initGrid();

        $scope.$on('policyupdate.success', function (event, mass) {
            $scope.initGrid();
        });
        $scope.$on('policyupdate.error', function (event, mass) {
            // trigger digest on each grid row form
            (vm.gridModel || []).forEach(function (rowItem) {
                rowItem['__trigger_digest'] = Date.now();
            });
        });

        $scope.$watch(() => vm.gridModel, function () {
            $scope.pageModel['__trigger_digest'] = Date.now();
        }, true);

        vm.reverse = vm.getProperty('order') === 'desc';

        // Filters
        const filterExp = $scope.wfSection.properties.filter_exp;
        if (filterExp) {
            vm.filterModel = {};
            vm.filterFn = function (item) {
                const evalExp = WorkflowUtil.evaluateExpression;
                return evalExp(filterExp, vm.filterModel, null, null, item);
            };
        }

        vm.addFieldCtrl = function (rowId, fieldCtrl) {
            // console.log('addFieldCtrl', rowId);
            if (!rowId) return;
            if (!rowFieldsCtrl[rowId]) rowFieldsCtrl[rowId] = [];
            rowFieldsCtrl[rowId].push(fieldCtrl);
        };

        vm.getGridFieldProperty = function (field, property, rowItem) {
            if (_.has(field, property)) {
                return $scope.evalExp(field[property], {item: rowItem});
            } else {
                return vm.getProperty(property, rowItem);
            }
        };

        vm.removeGridItem = function (gridItem) {
            vm.gridModel.splice(vm.gridModel.indexOf(gridItem), 1);
        };

        vm.addNewGridItem = function (copyFromItem) {
            var newItem = {
                id: 'new' + Date.now(),
                __new_row: true
            };
            copyFromItem && $scope.wfSection.fields.forEach(function (field) {
                if (!vm.getGridFieldProperty(field, 'readonly', copyFromItem) && vm.getGridFieldProperty(field, 'new_item', copyFromItem)) {
                    newItem[field.key] = copyFromItem[field.key];
                }
            });
            vm.gridModel.push(newItem);
        };

        vm.removeAllItems = function () {
            vm.gridModel.splice(0, vm.gridModel.length);
        };

        vm.copyItemsFromArray = function (newItems) {
            return (newItems || []).map(item => $timeout(1).then(_ => vm.addNewGridItem(item)));
        };

        vm.validate = function () {
            return vm.gridModel
                .filter(rowItem => {
                    // filter out rows without id and without controllers
                    return rowItem && rowItem.id && rowFieldsCtrl[rowItem.id];
                })
                .reduce((fcs, rowItem) => {
                    // join all field controllers in one array
                    return fcs.concat(rowFieldsCtrl[rowItem.id]);
                }, []
                ).reduce((valid, fc) => {
                    return fc.validate() && valid;
                }, true);
        };

        vm.getDeltaUpdate = function (updates = {}, forSave) {
            if (!vm.getProperty('visible')) return;

            // create empty object for new items
            vm.gridModel.forEach(rowItem => {
                if (rowItem && rowItem.id && (rowItem.id + '').startsWith('new')) {
                    _.setWith(updates, `${vm.getProperty('parent_key')}['${rowItem.id}']`, {}, Object);
                }
            });

            return $q.all(
                vm.gridModel.filter(rowItem => {
                    // filter out rows without id and without controllers
                    return rowItem && rowItem.id && rowFieldsCtrl[rowItem.id];
                }).reduce((fcs, rowItem) => {
                    // join all field controllers in one array
                    return fcs.concat(rowFieldsCtrl[rowItem.id]);
                }, []).map(fc => {
                    // mark all updates
                    return fc.getDeltaUpdate(updates, forSave);
                })
            ).then(() => {
                // mark removed rows
                var originalModel = _.get($scope.workflowOriginalModel, vm.getProperty('parent_key'), []);
                originalModel.forEach(function (rowItem) {
                    var rowId = rowItem.id;
                    if (!rowId) return;
                    if (_.find(vm.gridModel, {id: rowId})) return;
                    _.setWith(updates, `${vm.getProperty('parent_key')}['${rowId}']`, null, Object);
                });

                return $q.resolve();
            });
        };
    }

    angular.module('MyHippoCommons.Workflow').controller('WorkflowFieldController', WorkflowFieldController);
    WorkflowFieldController.$inject = ['$scope', '$timeout', '$q', '$filter', 'WorkflowUtil', 'WorkflowActions', '$attrs', '$log'];
    function WorkflowFieldController ($scope, $timeout, $q, $filter, WorkflowUtil, WorkflowActions, $attrs, $log) {
        // console.log('WorkflowFieldController');
        var vm = this;

        // inherit properties
        $scope.field = $scope.inheritProperties($scope.wfSection.properties, $scope.field);
        vm.getWfField = function () {
            return $scope.field;
        };
        vm.getProperty = function (property) {
            if (!_.has($scope.field, property)) return;
            return $scope.evalExp($scope.field[property], {item: $scope.sectionCtrl.isGrid ? vm.model : null});
        };

        vm.updateValueProperty = function () {
            // calculate value attribute and overwrite model
            if ($scope.field.hasOwnProperty('value')) {
                // console.log('field value', $scope.field.key, vm.getProperty('value'));
                _.set(vm.model, $scope.field.key, vm.getProperty('value'));
            }
        };

        vm.init = function (fieldModel) {
            vm.model = fieldModel;
            vm.updateValueProperty();
        };

        vm.resetField = function () {
            if (vm.field.formControl) {
                vm.options.resetModel();
                vm.field.formControl.$setUntouched();
                vm.field.formControl.$setPristine();
            }
        };

        vm.validate = function () {
            if (vm.getProperty('visible') && vm.field.formControl) {
                vm.field.formControl.$setTouched();
                vm.field.formControl.$validate();
                return vm.field.formControl.$valid;
            }
            return true;
        };
        $scope.$on('policyupdate.success', function (event, mass) {
            vm.updateValueProperty();
            try {
                vm.options.updateInitialValue();
            } catch (e) {}

            vm.validate(); // clear server errors
            if (vm.field.formControl) {
                vm.field.formControl.$setPristine();
            }
        });
        $scope.$on('policyupdate.error', function (event, mass) {
            vm.validate();
        });

        vm.getDeltaUpdate = function (updates, forSave) {
            if (vm.getProperty('readonly')) return;

            var originalModel = $scope.workflowOriginalModel;
            if ($scope.sectionCtrl.isGrid) {
                originalModel = _.chain($scope.workflowOriginalModel)
                    .get($scope.sectionCtrl.getProperty('parent_key'))
                    .find({id: vm.model.id})
                    .value();
            }

            var newValue = _.get(vm.model, $scope.field.key);
            var oldValue = _.get(originalModel, $scope.field.key);

            if (newValue === oldValue) return;
            if ($scope.field.type === 'number') {
                if (oldValue === undefined && newValue === null) return;
            }
            if ($scope.field.type === 'object') {
                if (_.isEqual(oldValue, newValue)) return;
            }

            updates = updates || {};
            if ($scope.sectionCtrl.isGrid) {
                var deltaUpdateKey = `${$scope.sectionCtrl.getProperty('parent_key')}.["${vm.model.id}"].${$scope.field.key}`;
                _.setWith(updates, deltaUpdateKey, newValue, Object);
            } else {
                // update workflow page info with changes - this would be the delta updates on the page
                _.set(updates, $scope.field.key, newValue);
            }
        };

        vm.onChange = function () {
            $timeout(function () {
                vm.getProperty('on_change');
                vm.getProperty('on_client_change');
                $scope.$emit('field.changed', $scope.field);
            }, 1);
        };

        var hideLabel = $scope.$eval($attrs.hideFieldLabel);
        vm.field = {
            key: $scope.field.key,
            defaultValue: vm.getProperty('client_default'),
            type: 'input',
            wrapper: hideLabel ? ['errorMessages', 'mainWrapper'] : ['errorMessages', 'formLabel', 'mainWrapper'],
            id: $scope.generateFieldId($scope.field.key),
            templateOptions: {
                label: $scope.field.label,
                key: $scope.field.key,
                inputWidth: vm.getProperty('input_width'),
                placeholder: $scope.field.placeholder,
                onChange: vm.onChange,
                focused: false,
                onFocus: function () {
                    vm.field.templateOptions.focused = true;
                },
                onBlur: function () {
                    vm.field.templateOptions.focused = false;
                },

                // tooltip info
                info: $scope.field.info,
                infoDocumentUrl: $scope.field.info_document_url
            },
            hideExpression: function () {
                return !vm.getProperty('visible');
            },
            expressionProperties: {
                'templateOptions.focus': function () {
                    return vm.getProperty('client_focus');
                },
                'templateOptions.referralStatus': function () {
                    var result = '';
                    ($scope.referrals || []).forEach(referral => {
                        if (referral.fields.indexOf($scope.field.key) !== -1) {
                            !referral.approved && (result = 'info'); // not set
                            referral.approved === false && (result = 'alert'); // declined
                            referral.approved === true && (result = ''); // approved
                        }
                    });
                    ($scope.underwriting || []).forEach(uw => {
                        if (uw.fields.indexOf($scope.field.key) !== -1) {
                            result !== 'alert' && (result = 'warning');
                        }
                    });
                    return result;
                },
                'templateOptions.serverError': function () {
                    return _.find($scope.workflowErrors, function (srvErr) {
                        if (!srvErr || !srvErr.fields) return;
                        var key = $scope.field.key;
                        if ($scope.sectionCtrl.isGrid) {
                            key = `${$scope.sectionCtrl.getProperty('parent_key')}.${vm.model.id}.${$scope.field.key}`;
                        }
                        return srvErr.fields.indexOf(key) !== -1;
                    });
                },
                'templateOptions.disabled': function ($viewModel, $modelValue, scope) {
                    console.log(scope.fields[0].key, vm.getProperty('readonly'));
                    return vm.getProperty('readonly');
                },
                'templateOptions.required': function () {
                    if (['checkbox', 'button'].indexOf(vm.field.type) !== -1) return false;
                    return vm.getProperty('required');
                },
                'templateOptions.labelColSpan': function () {
                    return vm.getProperty('label_colspan');
                }
            },
            validators: {},
            validation: {
                // show: true,
                messages: {
                    required: () => 'This field is required',
                    minlength: () => 'Value is below the minimum length allowed',
                    maxlength: () => 'Value exceeds the maximum length allowed',
                    min: () => 'Value is below the minimum allowed',
                    max: () => 'Value exceeds the maximum allowed',
                    datetime: () => 'Invalid date',
                    pattern: () => 'Invalid pattern'
                }
            },
            modelOptions: {
                allowInvalid: true,
                debounce: {
                    default: 200,
                    blur: 0
                },
                updateOn: 'default blur'
            },
            ngModelAttrs: {
                maxlengthHtmlAttr: {attribute: 'maxlength'},
                hpConvertToNumber: {attribute: 'hp-convert-to-number'},
                hpConvertToBoolean: {attribute: 'hp-convert-to-boolean'},
                dateTimeInput: {attribute: 'hp-date-time-input'},
                currencyInput: {attribute: 'currency-input'},
                minmax: {attribute: 'minmax'},
                dateInput: {attribute: 'date-input'},
                readonly: {attribute: 'readonly'},
                display: {attribute: 'display'},
                charCounter: {attribute: 'mh-char-counter'}
            }
        };

        if ($scope.field.format === 'date') {
            vm.field.type = 'maskedInput';
            vm.field.templateOptions.mask = '99/99/9999';
            vm.field.validators.dateFormat = {
                expression: function (viewValue, modelValue) {
                    var value = modelValue || viewValue;
                    if (!value || !value.replace(/[^0-9]/g, '')) return true;
                    return moment(value, 'MM/DD/YYYY', true).isValid();
                },
                message: `"Date is invalid"`
            };
            vm.field.parsers = [function toLowerCase (value) { // set model value to null if value is an empty string
                return !value ? null : value;
            }];
            if ($scope.field.min) {
                vm.field.validators.minDate = {
                    expression: function (viewValue, modelValue) {
                        var minDate = moment(vm.getProperty('min'), 'MM/DD/YYYY', true);
                        var value = moment(modelValue || viewValue, 'MM/DD/YYYY', true);
                        if (!minDate.isValid() || !value.isValid()) return true;
                        return minDate.diff(value) <= 0;
                    },
                    message: `"Date is before min allowed date"`
                };
            }
            if ($scope.field.max) {
                vm.field.validators.maxDate = {
                    expression: function (viewValue, modelValue) {
                        var maxDate = moment(vm.getProperty('max'), 'MM/DD/YYYY', true);
                        var value = moment(modelValue || viewValue, 'MM/DD/YYYY', true);
                        if (!maxDate.isValid() || !value.isValid()) return true;
                        return maxDate.diff(value) > 0;
                    },
                    message: `"Date is after max allowed date"`
                };
            }
        } else if ($scope.field.format === 'datetime') {
            vm.field.type = 'maskedInput';
            vm.field.templateOptions.mask = '99/99/9999 99:99:99';
            vm.field.templateOptions.dateTimeInput = '';
        } else if ($scope.field.format === 'short_date') {
            vm.field.type = 'maskedInput';
            vm.field.templateOptions.mask = '99/99';
            if ($scope.field.hasOwnProperty('pattern')) {
                vm.field.templateOptions.pattern = $scope.field.pattern;
                vm.field.validation.messages.pattern = '"Not a valid date"';
            }
        } else if ($scope.field.format === 'phone') {
            vm.field.type = 'maskedInput';
            vm.field.templateOptions.mask = '(999)-999-9999';
            vm.field.templateOptions.modelViewValue = false; // remove none numeric chars before saving
            if ($scope.field.hasOwnProperty('pattern')) {
                vm.field.templateOptions.pattern = $scope.field.pattern;
                vm.field.validation.messages.pattern = '"Not a valid phone number"';
            }
        } else if ($scope.field.format === 'file') {
            vm.field.type = 'uploadfile';
        } else if ($scope.field.format === 'multiple_files') {
            vm.field.type = 'multiple_uploadfile';
        } else if ($scope.field.format === 'url') {
            vm.field.type = 'url';
            vm.field.expressionProperties['templateOptions.display'] = function () {
                return vm.getProperty('display');
            };
        } else if ($scope.field.format === 'static') {
            vm.field.type = 'static';
        } else if ($scope.field.values || $scope.field.values_route) {
            vm.field.type = 'select';
            if ($scope.field.type === 'number') vm.field.templateOptions.hpConvertToNumber = true;
            if ($scope.field.type === 'boolean') vm.field.templateOptions.hpConvertToBoolean = true;

            vm.field.expressionProperties['templateOptions.options'] = function () {
                return $q.resolve().then(function () {
                    if ($scope.field.values) {
                        return {
                            values: vm.getProperty('values'),
                            order: vm.getProperty('order')
                        };
                    } else if (vm.getProperty('values_route') !== vm.previusWorkflowRoute) {
                        vm.previusWorkflowRoute = vm.getProperty('values_route');
                        if (!vm.getProperty('values_route')) return {};
                        return WorkflowUtil.getWorkflowRoute(vm.getProperty('values_route'));
                    }
                }).then(function (response) {
                    var dropdownValues = response.values || {};
                    var dropdownOrder = response.order || Object.keys(dropdownValues);
                    var currentOptions = vm.field.templateOptions.options || [];
                    return dropdownOrder.map(function (key) {
                        var name = dropdownValues[key];
                        if ($scope.field.type === 'number' && $scope.field.format === 'dollar' && !isNaN(name)) {
                            name = $filter('currency')(name, '$', (name * 100 % 100 === 0) ? 0 : 2);
                        }
                        var currentOption = currentOptions.find(o => o.value === key + '') || {};
                        return {value: key + '', name: name, $$hashKey: currentOption['$$hashKey']};
                    });
                });
            };
        } else if ($scope.field.format === 'card_info') {
            vm.field.type = 'cardInfo';
        } else if ($scope.field.type === 'boolean') {
            vm.field.type = 'checkbox';
            vm.field.templateOptions.onFocus = undefined;
        } else if ($scope.field.type === 'button') {
            vm.field.type = 'button';
            vm.field.key = '';
            vm.field.templateOptions.label = '';
            vm.field.templateOptions.buttonText = $scope.field.label;
            vm.field.expressionProperties['templateOptions.disabled'] = function () {
                if (!_.isUndefined(vm.getProperty('edit_readonly'))) {
                    return vm.getProperty('edit_readonly');
                }

                return vm.getProperty('readonly');
            };
            vm.field.templateOptions.onClick = function () {
                vm.getProperty('action');
            };
        } else if ($scope.field.type === 'number') {
            vm.field.templateOptions.type = 'number';
            if ($scope.field.format === 'dollar') {
                vm.field.templateOptions.type = 'text';
                vm.field.templateOptions.currencyInput = true;
            }
            vm.field.templateOptions.minmax = true;

            var updateValueOnValidationChange = function () {
                var min = vm.getProperty('min');
                var max = vm.getProperty('max');
                if (!vm.field.templateOptions.focused) {
                    var curValue = _.get(vm.model, $scope.field.key);
                    // console.log('updateValueOnValidationChange', min, max);
                    if (min !== undefined && curValue < min) {
                        _.set(vm.model, $scope.field.key, min);
                        vm.onChange();
                    } else if (max !== undefined && curValue > max) {
                        _.set(vm.model, $scope.field.key, max);
                        vm.onChange();
                    }
                }
            };

            var basicOnBlur = vm.field.templateOptions.onBlur;
            vm.field.templateOptions.onBlur = function () {
                basicOnBlur && basicOnBlur();
                updateValueOnValidationChange();
            };
            vm.field.expressionProperties['templateOptions.min'] = function () {
                var min = vm.getProperty('min');
                if (isNaN(min)) return;
                updateValueOnValidationChange();
                return min;
            };
            vm.field.expressionProperties['templateOptions.max'] = function () {
                var max = vm.getProperty('max');
                if (isNaN(max)) return;
                updateValueOnValidationChange();
                return max;
            };
            vm.field.expressionProperties['templateOptions.step'] = function () {
                var step = vm.getProperty('step');
                if (!step && $scope.field.format === 'dollar') step = 0.01;
                return step;
            };
        } else if ($scope.field.format === 'template') {
            vm.field.type = $scope.field.template;
        } else if ($scope.field.format === 'producer_effective_date') {
            vm.field.type = 'producer_effective_date';
            vm.field.templateOptions.on_save = $scope.field.on_save; // TODO: make sure this is a function
        } else {
            if ($scope.field.format === 'multiline') vm.field.type = 'textarea';
            if ($scope.field.format === 'password') vm.field.templateOptions.type = 'password';
            if ($scope.field.values_suggest || $scope.field.values_suggest_route) {
                vm.field.type = 'text-with-suggest';
                vm.field.templateOptions.options = vm.getProperty('values_suggest');
                vm.field.templateOptions.onSelect = function ($item, $model, $label, $event) {
                    if ($item.on_select) $scope.evalExp($item.on_select, {item: $scope.sectionCtrl.isGrid ? vm.model : null});
                };
                vm.field.expressionProperties['templateOptions.options'] = function () {
                    return $q.resolve().then(function () {
                        if ($scope.field.values_suggest) {
                            return vm.getProperty('values_suggest') || [];
                        } else if (vm.getProperty('values_suggest_route')) {
                            if (vm.getProperty('values_suggest_route') === vm.previusWorkflowRoute) return vm.suggestedValue;
                            vm.previusWorkflowRoute = vm.getProperty('values_suggest_route');
                            if (!vm.previusWorkflowRoute) return [];
                            return WorkflowUtil.getWorkflowRoute(vm.previusWorkflowRoute);
                        }
                    }).then(function (suggestedValue) {
                        vm.suggestedValue = suggestedValue;
                        return suggestedValue;
                    });
                };
            }

            vm.field.expressionProperties['templateOptions.minlength'] = function () {
                return vm.getProperty('min_length');
            };
            vm.field.expressionProperties['templateOptions.maxlength'] = function () {
                return vm.getProperty('max_length');
            };
            vm.field.expressionProperties['templateOptions.maxlengthHtmlAttr'] = function () {
                return vm.getProperty('max_length');
            };

            if ($scope.showCharCounter) {
                vm.field.expressionProperties['templateOptions.charCounter'] = function () {
                    if (vm.getProperty('max_length')) return true;
                };
            }
        }

        vm.fields = [vm.field];
    }

    angular.module('MyHippoCommons.Workflow').controller('WorkflowNavigationController', WorkflowNavigationController);
    function WorkflowNavigationController ($scope, $state, $stateParams, toaster) {
        'ngInject';
        // console.log('WorkflowNavigationController', $stateParams.pageKey, $scope.workflow);
        var vm = this;

        vm.navGroups = {};
        vm.getPagesByGroup = function (groupTitle) {
            if (!groupTitle) return $scope.workflow.pages;

            return $scope.workflow.pages.filter(function (page) {
                return page.properties.group_title === groupTitle;
            });
        };
        vm.getGroupIfNew = function (groupTitle) {
            if (!groupTitle || vm.navGroups[groupTitle]) return;

            var newNavGroup = {title: groupTitle, expanded: true, navVisible: true, completed: false};
            vm.navGroups[groupTitle] = newNavGroup;
            return newNavGroup;
        };

        vm.isPageCompleted = function (page) {
            return _.get($scope, `phases.completedPages["${page.properties.key}"].completed`, false);
        };
        vm.isGroupCompleted = function (group) {
            var result = true;
            group && $scope.workflow.pages.forEach(function (page) {
                if (page.properties.group_title !== group.title) return;
                result = result && vm.isPageCompleted(page);
            });
            return result;
        };

        vm.updatePagesStatus = function () {
            var workflowVisibleProp = $scope.workflow.properties.visible;

            $scope.workflow.pages.forEach(function (page) {
                // update page visibility
                var pageVisibleProp = page.properties.visible;
                if (pageVisibleProp === undefined) pageVisibleProp = workflowVisibleProp; // inherit from workflow if not defined
                page.isVisible = !!$scope.evalExp(pageVisibleProp);

                // update selected page
                if (page.properties.key === $stateParams.pageKey) {
                    page.isSelected = true;
                } else {
                    page.isSelected = false;
                }
            });
        };
        vm.updatePagesStatus();
        $scope.$on('policyupdate.success', function (event, mass) {
            vm.updatePagesStatus();
        });

        $scope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
            // console.log(event, toState, toParams, fromState, fromParams);
            vm.updatePagesStatus();
        });
    }

    angular.module('MyHippoCommons.Workflow').factory('WorkflowUtil', WorkflowUtil);
    function WorkflowUtil ($q, WorkflowActions) {
        var getWorkflowRouteFn;
        return {
            setWorkflowRouteFunction: function (fn) {
                getWorkflowRouteFn = fn;
            },
            getWorkflowRoute: function (route, params) {
                if (getWorkflowRouteFn) return getWorkflowRouteFn(route, params);
                return $q.resolve();
            },
            getWorkflowObjectInfo: function (obj) {
                if (!obj) return;

                function WorkflowObjectInfo (obj) {
                    _.extend(this, obj);
                    this.getTarget = function () { return obj; };
                }
                WorkflowObjectInfo.prototype.getValue = function (key) { return _.get(this, key); };
                WorkflowObjectInfo.prototype.setValue = function (key, value) { _.set(this, key, value); _.set(this.getTarget(), key, value); };
                WorkflowObjectInfo.prototype.deleteValue = function (key) { _.unset(this, key); _.unset(this.getTarget(), key); };
                WorkflowObjectInfo.prototype.minVersion = function (targetVer) {
                    const [verMaj, verMin] = this.quote.rater_version.split('.').map(v => v.padStart(2, '0'));
                    const [targetVerMaj, targetVerMin] = targetVer.split('.').map(v => v.padStart(2, '0'));
                    return Number(`${verMaj}${verMin}`) >= Number(`${targetVerMaj}${targetVerMin}`);
                };
                return new WorkflowObjectInfo(obj);
            },
            extendWithFunctions: function (model, functions) {
                functions = functions || {};
                Object.keys(functions).forEach(fn => {
                    model[fn] = functions[fn].bind(model);
                });
            },
            extendWorkflowModel: function (model, source) {
                const existingKeys = {};
                Object.keys(source).forEach(key => {
                    model[key] = source[key];
                    existingKeys[key] = true;
                });
                Object.keys(model).forEach(key => {
                    const existsInSource = existingKeys.hasOwnProperty(key);
                    const isFunction = typeof model[key] === 'function';
                    if (!existsInSource && !isFunction) delete model[key];
                });

                return model;
            },
            evaluateExpression: function (exp, policyInfo, roles, user, item) {
                if ((typeof exp === 'string') && (exp.substr(0, 2).toLowerCase() === 'f:')) {
                    try {
                        exp = transform(exp.substr(2));
                        var keys = Object.keys(policyInfo || {});
                        var varAllocation = keys.map(key => `var ${key} = policy['${key}'];\n`).join('');
                        // eslint-disable-next-line no-new-func
                        return (new Function(
                            'policy, roles, user, item, moment, clientActions',
                            `
                                ${varAllocation}
                                return (function () {
                                    ${exp.code}
                                    return functionCall();
                                })();
                            `
                        )(policyInfo, roles, user, item, moment, WorkflowActions));
                    } catch (e) {
                        return null;
                    }
                } else {
                    return exp;
                }
            },

            getWorkflowFieldEx: function (wf, key) {
                // console.log(wf, key);
                for (let pageId in wf.pages) {
                    let page = wf.pages[pageId];
                    if (page.sections) {
                        for (let sectionId in page.sections) {
                            let section = page.sections[sectionId];
                            let parentKey = section.properties.parent_key ? (section.properties.parent_key + '.') : '';
                            if (section.fields) {
                                for (let fieldId in section.fields) {
                                    let field = section.fields[fieldId];
                                    if (parentKey + field.key === key) {
                                        return {page, section, field};
                                    }
                                }
                            }
                        }
                    }
                    if (page.fields) {
                        for (let fieldId in page.fields) {
                            let field = page.fields[fieldId];
                            if (field.key === key) {
                                return {page, field};
                            }
                        }
                    }
                }
            }
        };
    }

    angular.module('MyHippoCommons.Workflow').factory('WorkflowActions', WorkflowActions);
    WorkflowActions.$inject = ['$log', '$rootScope', '$q', '$window', '$injector', 'APIService', 'toaster', 'spinnerService', '$state', '$stateParams', '$mdDialog'];
    function WorkflowActions ($log, $rootScope, $q, $window, $injector, APIService, toaster, spinnerService, $state, $stateParams, $mdDialog) {
        return {
            save: function () {
                $rootScope.$broadcast('saveWorkflowModel');
            },
            exportPerilFactors: function (policyId, factors) {
                const cols = ['index', 'name'];
                for (let i = 1; i <= 10; i++) cols.push('p' + i);
                var result = cols.join(',') + '\n';
                result = factors.reduce((accumulator, val) => {
                    accumulator += cols.map(c => (val[c] !== undefined ? val[c] : '').toString().replace(/,/gi, ' ')).join(',') + '\n';
                    return accumulator;
                }, result);
                var blob = new Blob([result], {type: 'text/tsv;charset=utf-8'});
                saveAs(blob, 'peril-factors-' + policyId + '-' + new Date().toISOString() + '.csv');
            },
            openStreetView: function (address) {
                return APIService.getLatLng(address).then(latLng => {
                    $window.open(`http://maps.google.com/maps?q=&layer=c&cbll=${latLng.lat},${latLng.lng}&cbp=11,0,0,0,0`);
                }).catch($log.error);
            },
            getPolicyLogDelta: function (logId) {
                return $injector.get('PolicyService').findPolicyLogDelta(logId).then(delta => {
                    $injector.get('PolicyModals').openConfirmation('Log details', `<pre>${JSON.stringify(delta, null, 4)}</pre>`);
                }).catch($log.error);
            },

            regenerateClaimPaymentCheck: function (claimId, paymentId) {
                spinnerService.show('globalSpinner');
                return APIService.regenerateClaimPaymentCheck(claimId, paymentId).then(result => {
                    $log.info('generated claim payment check doc', result);
                    spinnerService.hide('globalSpinner');
                    toaster.pop('success', 'Claim Payment Check', 'Successfully generated!');
                    $rootScope.$broadcast('updateWorkflowModel', result);
                }).catch(() => {
                    spinnerService.hide('globalSpinner');
                    toaster.pop('error', 'Claim Payment Check', 'Unknown Error Occurred');
                });
            },

            generateCancellationAssistDoc: function (policyId) {
                spinnerService.show('globalSpinner');
                return $injector.get('PolicyService').generateCancellationAssistanceDoc(policyId).then(result => {
                    $log.info('generated cancellation assistance', result);
                    spinnerService.hide('globalSpinner');
                    toaster.pop('success', 'Cancellation Assist Document', 'Successfully generated!');
                    $state.transitionTo($state.current, $stateParams, {reload: true});
                }).catch(() => {
                    spinnerService.hide('globalSpinner');
                    toaster.pop('error', 'Cancellation Assist Document', 'Unknown Error Occurred');
                });
            },
            generateCancellationAssistLink: function (policyId) {
                spinnerService.show('globalSpinner');
                return $injector.get('PolicyService').generateCancellationAssistanceLink(policyId).then(result => {
                    $log.info('generated cancellation assistance', result);
                    spinnerService.hide('globalSpinner');
                    toaster.pop('success', 'Cancellation Assist Link', 'Successfully generated!');
                    $state.transitionTo($state.current, $stateParams, {reload: true});
                }).catch(() => {
                    spinnerService.hide('globalSpinner');
                    toaster.pop('error', 'Cancellation Assist Link', 'Unknown Error Occurred');
                });
            },
            sendDocumentToPrinting: function (policyId, type) {
                spinnerService.show('globalSpinner');
                return $injector.get('PolicyService').sendDocumentToPrinting(policyId, type).then(result => {
                    $log.info('Sent Document to Printing', result);
                    spinnerService.hide('globalSpinner');
                    $state.transitionTo($state.current, $stateParams, {reload: true});
                    toaster.pop('success', 'Sent Document to Printing', 'Successfully Sent!');
                }).catch((err) => {
                    spinnerService.hide('globalSpinner');
                    toaster.pop('error', 'Sent Document to Printing Failed', err);
                });
            },
            resendCustomerAgreementEmail: function (policy) {
                const { checkout_data: checkoutData, status } = policy;
                if (!checkoutData.customer_approval && ['active', 'pending_active'].indexOf(status) > -1) {
                    const policyId = policy.id;
                    const firstName = _.get(policy, 'personal_information.first_name');
                    const lastName = _.get(policy, 'personal_information.last_name');
                    const email = _.get(policy, 'personal_information.email');
                    return APIService.resendCustomerAgreementEmail(policyId).then(() => {
                        spinnerService.show('globalSpinner');
                        $mdDialog.show({
                            template: `
                                <basic-modal title="'Email Send Successfully'" primary-text="'OKAY'" can-close='true'>
                                    <p class="resend-customer-email-body">We just sent <b>{{firstName}} {{lastName}}</b> the terms and conditions acceptance email to <b>{{email}}</b></p>
                                </basic-modal>
                            `,
                            controller: function($scope) {
                                $scope.firstName = firstName;
                                $scope.lastName = lastName;
                                $scope.email = email;
                            }
                        });
                        $log.info('Sent email to customer');
                    }).catch((err) => {
                        toaster.pop('error', 'Unable to send customer agreement email', err);
                    }).then(() => {
                        spinnerService.hide('globalSpinner');
                    });
                } else {
                    $log.info('Customer already accepted terms. Not sending agreement email.');
                }
            }
        };
    }

    angular.module('MyHippoCommons.Workflow').config(FormlyConfig);
    FormlyConfig.$inject = ['formlyConfigProvider', 'formlyApiCheck'];
    function FormlyConfig (formlyConfigProvider, formlyApiCheck) {
        // window.apiCheck.globalConfig.disabled = true;// production config
        // formlyConfigProvider.disableWarnings = true; // production config
        formlyConfigProvider.extras.defaultHideDirective = 'ng-show';

        formlyConfigProvider.setWrapper({
            name: 'mainWrapper',
            overwriteOk: true,
            templateUrl: 'formly/main-wrapper.html'
        });

        formlyConfigProvider.setWrapper({
            name: 'formLabel',
            overwriteOk: true,
            templateUrl: 'formly/form-label.html'
        });

        formlyConfigProvider.setWrapper({
            name: 'errorMessages',
            overwriteOk: true,
            templateUrl: 'formly/error-messages.html'
        });
    }

    angular.module('MyHippoCommons.Workflow').run(FormlyTypes);
    FormlyTypes.$inject = ['formlyConfig', '$templateCache', 'toaster', 'UploadService', 'APIService'];
    function FormlyTypes (formlyConfig, $templateCache, toaster, UploadService, APIService) {
        // This will go over all the directives in 'MyHippoFormly.Directives' module
        // and add the type to formlyConfig
        function getDirectiveNamesForModule(name) {
            const camelCaseToDash = str => str.replace(/([a-zA-Z])(?=[A-Z])/g, '$1-').toLowerCase();
            const invokes = angular.module(name)._invokeQueue;
            return invokes.filter(i => i[1] === 'directive').map(i => camelCaseToDash(i[2][0]));
        }
        getDirectiveNamesForModule('MyHippoFormly.Directives').forEach(directiveName => {
            formlyConfig.setType({
                name: directiveName,
                template: `<${directiveName} options="options" model="model"></${directiveName}>`,
                wrapper: [],
                overwriteOk: true
            });
        });

        // define checkbox field
        formlyConfig.setType({
            name: 'checkbox',
            template: `
                <div class="form-control form-control-toggle">
                    <div class="hippo-toggle-wrapper">
                        <input type="checkbox" class="cmn-toggle-round-flat formly-field-checkbox" ng-model="model[options.key]">
                        <label for="{{id}}" data-on="Yes" data-off="No"></label>
                    </div>
                </div>
            `,
            wrapper: [],
            overwriteOk: true
        });

        // define input field
        formlyConfig.setType({
            name: 'input',
            template: '<input class="form-control" ng-model="model[options.key]" ng-style="{width: to.inputWidth}">',
            wrapper: [],
            overwriteOk: true
        });

        // TODO: Adrian: this is to specific code, it needs to be more generic, refactor inside producer portal or built a way to add more generic types
        formlyConfig.setType({
            name: 'producer_effective_date',
            template: `
                <input date-input ng-change="validateInput()" class="form-control with-edit-button" ng-model="model[options.key]" ng-style="{width: to.inputWidth}" ng-readonly="!isEditMode">
                <div class="button-control">
                    <button class="edit" ng-show="!isEditMode && canEditEffectiveDate" ng-click="beginEditMode()">Edit</button>
                    <button class="save" ng-show="isEditMode" ng-click="save()">Save</button>
                    <button class="cancel" ng-show="isEditMode" ng-click="cancel()">Cancel</button>
                </div>
                <div class="edit-result-message" ng-show="isEditMode">Changing the effective date might affect premium</div>
                <span class="edit-result-message" ng-class="{error: form.$error.accepted || showError}" ng-bind="message"></span>`,
            wrapper: [],
            overwriteOk: true,
            controller: function ($scope) {
                $scope.originalValue = $scope.model[$scope.options.key];
                $scope.isEditMode = false;
                $scope.message = '';
                $scope.canEditEffectiveDate = moment($scope.model[$scope.options.key], 'MM/DD/YYYY').isAfter(moment()) && $scope.model['status'] === 'pending_active';

                $scope.beginEditMode = function () {
                    $scope.message = '';
                    $scope.isEditMode = true;

                    // force showing error as soon as the user types invalid input,
                    // rather than waiting for an initial blur
                    for (const key in $scope.form) {
                        if (!key.startsWith('$')) {
                            $scope.form[key].$touched = true;
                            $scope.form[key].$untouched = false;
                        }
                    }
                };

                $scope.validateInput = function () {
                    let isValid = true;
                    $scope.message = '';
                    $scope.form.$setValidity('accepted', true);

                    const input = $scope.model[$scope.options.key];
                    if (!/^(0\d|1[012])[/-](0\d|1\d|2\d|3[01])[/-]20(1|2)\d$/.test(input)) {
                        $scope.message = 'Invalid date format.  Please use MM/DD/YYYY format.';
                        isValid = false;
                    }

                    const enteredDate = moment($scope.model[$scope.options.key], 'MM/DD/YYYY');
                    const isTooEarly = enteredDate.isBefore(moment().add(1, 'day'), 'day');
                    const isTooLate = enteredDate.isAfter(moment().add(90, 'days'), 'day');

                    if (!enteredDate.isValid()) {
                        $scope.form.$setValidity('accepted', false);
                        $scope.message = 'Date entered is invalid';
                        isValid = false;
                    } else if (isTooEarly || isTooLate) {
                        $scope.form.$setValidity('accepted', false);
                        $scope.message = 'Date has to be within the next 90 days';
                        isValid = false;
                    }

                    $scope.showError = !isValid;
                };

                function stopEditing () {
                    $scope.isEditMode = false;
                    $scope.showError = false;
                    $scope.message = '';
                }

                $scope.save = _.debounce(function () {
                    const valueToSave = $scope.model[$scope.options.key];
                    return $scope.options.templateOptions.on_save(valueToSave).then(() => {
                        stopEditing();
                        $scope.originalValue = valueToSave;
                        $scope.form.$setValidity('accepted', true);
                        $scope.form.$setPristine(true);
                        $scope.message = 'Saved';
                    }, (e) => {
                        $scope.form.$setValidity('accepted', false);
                        if (e.startsWith('incorrect date format or outside of boundary') || e === 'operation validations failed') {
                            $scope.message = 'Date has to be within the next 90 days';
                        } else {
                            $scope.message = e;
                        }
                    });
                }, 3000, {leading: true, trailing: false});

                $scope.cancel = function () {
                    $scope.message = '';
                    stopEditing();
                    $scope.model[$scope.options.key] = $scope.originalValue;
                    $scope.form.$setPristine(true);
                };
            }
        });

        // define textarea field
        formlyConfig.setType({
            name: 'textarea',
            template: '<textarea class="form-control" ng-model="model[options.key]" msd-elastic></textarea>',
            wrapper: [],
            overwriteOk: true
        });

        // define static field
        formlyConfig.setType({
            name: 'static',
            template: '<hp-static-field class="" ng-model="model[options.key]" style=""></hp-static-field>',
            wrapper: [],
            overwriteOk: true
        });

        // define select field
        formlyConfig.setType({
            name: 'select',
            template: '<select class="form-control" ng-model="model[options.key]" ng-style="{width: to.inputWidth}"></select>',
            wrapper: [],
            overwriteOk: true,
            defaultOptions (options) {
                /* jshint maxlen:195 */
                let ngOptions = options.templateOptions.ngOptions || `option[to.valueProp || 'value'] as option[to.labelProp || 'name'] group by option[to.groupProp || 'group'] for option in to.options`;
                return {
                    ngModelAttrs: {
                        [ngOptions]: {
                            value: options.templateOptions.optionsAttr || 'ng-options'
                        }
                    }
                };
            }
        });

        // define ui-select field
        formlyConfig.setType({
            name: 'ui-select',
            template: '<ui-select ng-model="model[options.key]" theme="bootstrap" ng-required="{{to.required}}" ng-disabled="{{to.disabled}}" reset-search-input="false"> <ui-select-match placeholder="{{to.placeholder}}"> {{$select.selected[to.labelProp || \'name\']}} </ui-select-match> <ui-select-choices group-by="to.groupProp || \'group\'" repeat="option[to.valueProp || \'value\'] as option in to.options | filter: $select.search"> <div ng-bind-html="option[to.labelProp || \'name\'] | highlight: $select.search"></div> </ui-select-choices> </ui-select>',
            wrapper: [],
            overwriteOk: true
        });

        // define text-with-suggest field
        formlyConfig.setType({
            name: 'text-with-suggest',
            template: `<input type="text" class="form-control"
                ng-model="model[options.key]"
                uib-typeahead="option.value as option.value for option in to.options | filter:$viewValue | limitTo:40"
                typeahead-min-length="0"
                typeahead-append-to-body="true"
                typeahead-on-select="to.onSelect($item, $model, $label)"
                typeahead-template-url="formly/suggest-item-template.html"
            >`,
            wrapper: [],
            overwriteOk: true
        });

        // define datepicker field
        formlyConfig.setType({
            name: 'datepicker',
            template: '<hp-date-picker ng-model="model[options.key]"></hp-date-picker>',
            wrapper: []
        });

        // define upload field
        formlyConfig.setType({
            name: 'uploadfile',
            template: '<hp-file-upload ng-model="model[options.key]"></hp-file-upload>',
            wrapper: []
        });

        // define upload field
        formlyConfig.setType({
            name: 'multiple_uploadfile',
            template: `<div class="hp-field-uploadfile"><hp-multiple-new-file-upload max-filename-display="70" parent-collection="model" parent-key="options.key"></hp-multiple-new-file-upload></div>`,
            wrapper: []
        });

        // define upload field
        formlyConfig.setType({
            name: 'liveaddress',
            template: '<hp-live-address ng-model="model[options.key]"></hp-live-address>',
            wrapper: []
        });

        // define url field
        formlyConfig.setType({
            name: 'url',
            template: '<hp-url-input ng-model="model[options.key]"></hp-url-input>',
            wrapper: [],
            defaultOptions: {templateOptions: {
                display: ''
            }}
        });

        // define stripe card field
        formlyConfig.setType({
            name: 'cardInfo',
            template: '<hp-card-info ng-model="model[options.key]"></hp-card-info>',
            wrapper: [],
            defaultOptions: {templateOptions: {
                display: ''
            }}
        });

        // define stripe card field
        formlyConfig.setType({
            name: 'stripeCard',
            template: '<hp-stripe-card ng-model="model[options.key]"></hp-stripe-card>',
            wrapper: [],
            defaultOptions: {
                ngModelAttrs: {
                    stripe: {bound: 'stripe'},
                    cardElement: {bound: 'stripe-card'}
                }
            }
        });

        // define button field
        formlyConfig.setType({
            name: 'button',
            template: '<button class="cBtnRound" ng-click="to.onClick()" ng-model="model[options.key]">{{to.buttonText}}</button>',
            wrapper: []
        });

        formlyConfig.setType({
            name: 'maskedInput',
            extends: 'input',
            template: '<input class="form-control" ng-model="model[options.key]" ng-style="{width: to.inputWidth}" />',
            defaultOptions: {
                ngModelAttrs: {
                    mask: {attribute: 'ui-mask'},
                    maskPlaceholder: {attribute: 'ui-mask-placeholder'},
                    modelViewValue: {attribute: 'model-view-value'},
                    maskOptions: {bound: 'ui-options'},
                    uiMaskPlaceholderChar: {attribute: 'ui-mask-placeholder-char'}
                },
                templateOptions: {
                    maskPlaceholder: '',
                    uiMaskPlaceholderChar: '_',
                    modelViewValue: true,
                    maskOptions: {clearOnBlur: false, allowInvalidValue: true}
                }
            }
        });
    }

    angular.module('MyHippoCommons.Workflow').run(FormlyTemplates);
    FormlyTemplates.$inject = ['$templateCache'];
    function FormlyTemplates ($templateCache) {
        $templateCache.put('formly/main-wrapper.html', `
            <div class="form-group iconCircle hp-field hp-field-{{fields[index].type}}" ng-class="{'has-error has-feedback alert': showError || to.serverError, 'is-warning-error': to.isWarningError, 'has-changes has-warning': fc.$dirty, 'isFocus2': to.focused, 'isDisabled': to.disabled, [to.referralStatus]: to.referralStatus}">
                <formly-transclude></formly-transclude>
            </div>
        `);

        $templateCache.put('formly/form-label.html', `
            <div class="row formly-form-field" field-key="{{to.key}}" field-id="{{id}}">
                <div class="col-md-{{to.labelColSpan || 3}} formly-label" ng-if="to.label">
                    <label for="{{id}}" class="control-label {{to.labelSrOnly ? 'sr-only' : ''}}" ng-class="{'required' : to.required}">
                        <span markdown-to-html="to.label"></span>
                    </label>
                    <a href="javascript: void(0);"
                        ng-if="to.info"
                        tabindex="-1"
                        class="cBtn-info ion-information-circled"
                        popover-placement="right"
                        popover-append-to-body="false"
                        popover-is-open="infoPopover.isOpen"
                        uib-popover-template="'formly/field-info-popover.html'"
                        popover-class="popover-workflow-field-info">
                    </a>
                </div>
                <div class="col-md-{{12 - (to.label ? (to.labelColSpan || 3) : 0)}} formly-control">
                    <formly-transclude></formly-transclude>
                </div>
            </div>
        `);

        $templateCache.put('formly/error-messages.html', `
            <formly-transclude></formly-transclude>
            <div ng-messages="fc.$error" ng-if="showError || to.serverError" class="error-messages" ng-messages-multiple>
                <span class="ion-alert-circled form-control-feedback" tooltip-placement="left" uib-tooltip-template="'formly/error-messages-tooltip.html'"></span>
            </div>
        `);

        $templateCache.put('formly/error-messages-tooltip.html', `
            <div ng-message="{{ ::name }}" ng-repeat="(name, message) in ::options.validation.messages" class="message">
                {{ message(fc.$viewValue, fc.$modelValue, this)}}
            </div>
            <div class="message server-error" ng-if="to.serverError">
                {{to.serverError.agent_message}}
            </div>
        `);

        $templateCache.put('formly/field-info-popover.html', `
            <i class="btn-close ion-close" ng-click="infoPopover.isOpen = false;" />
            <div ng-bind-html="to.info || field.info"></div>
            <a ng-href="{{to.infoDocumentUrl || field.info_document_url}}" target="_blank" ng-if="to.infoDocumentUrl || field.info_document_url">Download Form</a>
        `);

        $templateCache.put('formly/suggest-item-template.html', `
            <a>
                <span ng-bind-html="match.label | uibTypeaheadHighlight:query"></span>
                <span ng-if="match.model.description">
                    <br />
                    <span ng-bind-html="match.model.description | uibTypeaheadHighlight:query"></span>
                </span>
            </a>
        `);
    }
})(window.angular, window.moment, window._, window.saveAs);
